.chat-body-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.user {
    display: flex;
    align-self: flex-end;
    padding: 16px;
    margin-left: 51px;
    margin-bottom: 7px;
    border-radius: 14px;
    background-color: #2D9CDB;
}

.received {
    display: flex;
    align-self: flex-start;
    padding: 16px;
    margin-right: 51px;
    margin-bottom: 7px;
    border-radius: 14px;
    background-color: #2A2A2A;
}

.pending {
    display: flex;
    gap: 7px;

    &> span {
        background-color: #D9D9D9;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}

.main-container {
    display: flex;
}

.main-burger-display {
    display: flex;
    max-width: 382px;
    width: 100%;
    background-color: #1B1B1B;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.main-burger-hidden {
    width: 0;
    padding: 0;
    overflow: hidden;
}

.home-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

@media screen and (max-width: 433px) {
    .home-page-hidden {
        width: 50px;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
    }
}


// .main-container {
//     display: flex;
//     overflow: hidden; // hides the part of the right child that goes beyond the screen boundary
//   }

//   .main-burger-display {
//     display: flex;
//     max-width: 382px;
//     background-color: #1B1B1B;
//     width: 100%;
//     transform: translateX(-382px); // starts the left child off-screen to the left
//     transition: transform 0.5s ease-in-out; // transition for transform change
//   }

//   .home-page-container {
//     display: flex; 
//     flex-direction: column;
//     min-height: 100vh;
//     width: 100vh;
//     transform: translateX(-382px);
//     transition: transform 0.5s ease-in-out; // transition for transform change
//   }

//   .main-burger-hidden {
//     transform: translateX(382px);
//     //     width: 0; 
// //     padding: 0;
// //     overflow: hidden; // when showing the left child, slide it in from the left
//   }

//   .home-page-hidden {
//     transform: translateX(382px); // when hiding the left child, push the right child out of screen boundary
//   }
.sign-in-burger-container{
    display: flex;
    height: 100%;
    align-items: flex-end;
}

.sign-in-burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 287px;
    height: 153px;
    border-radius: 15px;
    background-color: #2A2B2C;
}

.sign-in-burger-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 52px;
    width: 100%;
    background-color: #323639;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    &> img {
        width: 12.75px;
        height: auto;
        margin-left: 14px;
        margin-right: 7px;
    }
    &> h1 {
        font-family: 'Outfit';
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #D6D6D6;
    }
}

.sign-in-burger-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding-inline: 8px;
    padding-bottom: 8px;
    padding-top: 10px;

    &> p {
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #F0F0F0;
        text-align: start;
        margin-bottom: 13px;
    }
}

.sign-in-burger-form {
    display: flex;
    gap: 12px;

    &> input {
        background-color: #4E4E4E;
        border-radius: 3px 3px 3px 10px;
        height: 45px;
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba(250, 250, 250, 0.5);
        opacity: 0.85;
        padding-left: 14.9px;
    }
    &> button {
        width: 80px;
        height: 45px;
        border-radius: 3px 3px 10px 3px;
        background-color: #171717;
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        text-align: center;
        color: white;
    }
}

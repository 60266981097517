.insight-chat-body-container {
    display: flex;
    justify-content: center;
}

.insight-chat-body-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    max-width: 426px;
    width: 100%;
    min-height: 295px;
    background: #2A2A2A;
    border: 1px solid #303030;    
    border-radius: 14px;
    overflow: hidden;
}

.insight-chat-body-header {
    display: flex;
    flex-direction: column;
    padding-inline: 8px;
    padding-top: 8px;
    height: 100%;

    &> h1 {
        font-family: 'Outfit';
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #F0F0F0;
        opacity: 0.85;
        margin-bottom: 3px;
    }
    &> p {
        font-family: 'Outfit';
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #F0F0F0;
        opacity: 0.85;
    }
}

.insight-chat-body-footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-inline: 8px;
    background-color: #212121;

    &> img {
        width: 16px;
        height: auto;
        margin-right: 5px;
    }

    &> p {
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #FEFEFE;
    }
}

.insight-chat-body-img {
    display: flex;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
    width: 100%;
    height: 141px;
    margin-bottom: 12px;

    &> img {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: auto;
    }
}
.burger-menu-guides-header {
    display: flex;
    margin-bottom: 15px;

    &> img {
        width: 20;
        height: auto;
        margin-inline: 5px;
    }
    &> h1 {
        font-family: 'Outfit';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #D9D9D9;
    }
}

.burger-menu-guides-list {
    display: flex;
    width: 100%;
    gap: 24px;
    overflow-x: scroll;
}

.burger-menu-one-guide {
    width: 134px;
    height: 159px;
    background-color: #363738;
    padding: 6px;
    border-radius: 3px;

    &> img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    &> p {
        font-family: 'Outfit';
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #D9D9D9;
    }
}
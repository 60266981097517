@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

$base-background-color: #121212;
$base-text-color: #F0F0F0;

* {
  padding: 0;
  margin: 0;
  border: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-family: 'Outfit', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  max-width: 100vw;
  overflow-x: clip;
  background-color: $base-background-color;
  color: $base-text-color;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}


// html,
// body {
//   -ms-overflow-style: none;
//   scrollbar-width: none; 
// }

// html::-webkit-scrollbar, body::-webkit-scrollbar {
//   display: none;
// }
.chat-head-container {
    padding-top: 74px;
    margin-bottom: 51px;
}

.chat-head-container > h1 {
    font-family: 'Outfit';
    font-weight: 500;
    font-size: 58px;
    line-height: 73px;
    color: #F0F0F0;
    opacity: 0.75;
    margin-bottom: 24px;
}

.chat-head-container > p {
    font-family: 'Outfit';
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #F0F0F0;
    opacity: 0.75;
}

.chat-head-container > span {
    font-size: 12px;
    color: #B9B9B9;
}

@media screen and (max-width: 393px) {
    .chat-head-container {
        margin-bottom: 32px;
    }
    .chat-head-container h1 {
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 19px;
    }
}
.insight-container {
    display: flex;
    flex-direction: column;
    max-width: 663px;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
}

.insight-top-menu {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-inline: 17.5px;
    width: 100%;
    height: 60px;
    background-color: #2A2A2A;

    &> img {
        width: 34px;
        height: auto;
    }
    &> p {
        align-self: center;
    }
}

.insight-content {
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    padding-top: 15px;
    padding-bottom: 67px;
    width: 100%;
    height: 100%;
    background-color: #2A2A2A;

    &> img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 22px;
    }
}

.insight-text-area {
    &> h1 {
        margin-bottom: 28px;
    }
    &> h4 {
        margin-bottom: 29px;
    }
}
.burger-menu-insights-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 29px;
}

.burger-menu-insights-header {
    display: flex;
    margin-bottom: 18px;

    &> img {
        width: 16px;
        height: auto;
        margin-inline: 6px;
    }
    &> h1 {
        font-family: 'Outfit';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #D9D9D9;
    }
}

.burger-menu-insights-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.burger-menu-one-insight {
    display: flex;
    position: relative;
    width: 100%;
    height: 74px;
    background-color: #303030;
    border-radius: 15px;

    &> img {
        margin: 8px;
        width: 63px;
        height: auto;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
    }

    &> div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &> h1, h2 {
            font-family: 'Outfit';
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #AAAAAA;
            max-width: 200px;
        }
        &> h2 {
            color: #D9D9D9;
        }
    }
}

.not-active {
    opacity: 0.5;
}

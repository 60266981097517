.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 16px;
}

.input-section {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 528px;
    width: 100%;
    height: 50px;
    background-color: #151621;
    border: 1px solid #26263E;
    border-radius: 14px;
    margin-bottom: 33px;
}

.input-section > span {
    width: 6px;
    height: 22px;
    background-color: #5F01A8;
    margin-inline: 8px;
    border-radius: 3px;
}

.input-section form, input {
    width: 100%;
    background-color: transparent;

    ::placeholder {
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #E0E0E0;
        opacity: 0.85;
    }
    :focus {
        outline: none;
    }
}

.input-section input {
    color: #E0E0E0;
    font-family: 'Outfit';
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #E0E0E0;
    opacity: 0.85;
}

.input-button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 40px;
    border-radius: 12px;
    background: linear-gradient(180deg, #6700B7 0%, #3C006B 100%);
    margin-inline: 7px;
    cursor: pointer;
}

.headline-absolute {
    position: absolute;
    top: -60px;
    left: 0;
    
    &> img {
        margin-top: 5px;
        margin-left: 15px;
    }
}

@media screen and (max-width: 393px) {
    .input-section {
        max-width: 528px;
        width: 100%;
        margin-bottom: 40px;
    }
    .input-section > span {
        display: none;
    }
    .input-section > form {
        margin-left: 14px;
    }
    .input-button {
        margin-inline: 5px;
    }
}
.burger-menu-header-container {
    position: relative;

    &> img {
        position: absolute;
        top: -62px;
        left: 0;
        width: 24px;
        height: auto;
    }
}

.hide-settings {
    display: none;
}

.burger-menu-header-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;

    &> h1 {
        font-family: 'Outfit';
        font-weight: 600;
        font-size: 33.6px;
        line-height: 42px;
        color: #D9D9D9;
        margin-bottom: 6px;
    }
    &> p {
        font-family: 'Outfit';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #D9D9D9;
    }
}

.modal-overlay-settings {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}

.modal-settings {
    // display: flex;
    // justify-content: center;
    width: 20px;
    height: 20px;
    outline: none;
    background-color: #2A2A2A;
    border-radius: 15px;
}


@media screen and (max-width: 393px) {
    .burger-menu-header-container {    
        &> img {
            top: 13px;
            left: 290px;
        }
    }
}
.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 16px;
    overflow-y: auto;
}

.chat-spacing {
    position: relative;
    max-width: 663px;
    height: calc(100vh - 1000px);
}

.modal-overlay {
    padding-top: 90px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(3px);
}

.modal {
    display: flex;
    justify-content: center;
    outline: none;
    background-color: #2A2A2A;
}